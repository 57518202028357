#keyos-player-holder {
    position: absolute;
}
.keyos-logo {
    display: none;
}
.keyos-default .vjs-big-play-button {
    background-image: url(../img/baseline_play_arrow_white_48dp.png);
    background-color: transparent;
    border: none;
}
.video-js:hover .vjs-big-play-button,
.video-js .vjs-big-play-button:focus {
  border-color: transparent;
  background-color: transparent;
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  -ms-transition: all 0s;
  -o-transition: all 0s;
  transition: all 0s; 
}

/* LIVE */
.live .vjs-current-time{
    display: none;
}
.live .vjs-duration{
    display: none;
}

/* RADIO OPEN */
.open-radio-player .vjs-big-play-button{
    visibility: hidden;
}
.open-radio-player .vjs-control-bar {
    display: flex !important;
    visibility: visible;
    opacity: 1 !important;
}
.open-radio-player .vjs-fullscreen-control {
    display:none !important;
}
.open-radio-player .video-js .vjs-progress-control .vjs-mouse-display {
    display:none !important;
}
.open-radio-player .vjs-time-tooltip {
    display:none !important;
}